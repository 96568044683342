import React, { useState, useEffect, useContext, useCallback } from 'react'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import { context } from '../../store'
import callAPI from '../../lib/callAPI'

import { AccountLayout, AccountSubMenu,
    AccountGiftListList, AccountGiftListForm,
    AccountGiftListOrdersList } from '../../components'

const AccountGiftListOrdersTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext
  const { store } = useContext(context)

  const [giftlist, setGiftList] = useState()

  const fetchGiftList = useCallback(
    (id_giftlist) => {
      return callAPI(store, {
        href: store.app.endpoints.my_giftlist.href.replace('{id_giftlist}', id_giftlist),
        method: store.app.endpoints.my_giftlist.method
      })
    }, [])

  useEffect(() => {
    const initialize = async () => {
      if (typeof window !== 'undefined') {
        const qs = queryString.parse(window.location.search)
        if (qs && qs.id && +qs.id > 0) {
          const result = await fetchGiftList(qs.id)
          if (result && +result.id_giftlist === +qs.id) {
            setGiftList(result)
          }
        }
      }
    }
    initialize()
  }, [fetchGiftList])

  const cancel = (e) => {
    setGiftList(null)
    navigate('?')
  }

  return (
    <AccountLayout lang={lang} switcher={page}>
  		<div className="wrapper d-flex flex-column flex-md-row">
        <AccountSubMenu lang={lang} />

  			<div className="content">
          {giftlist ? (
            <AccountGiftListOrdersList giftlist={giftlist} lang={lang} />
          ) : (
            <p></p>
          )}

        </div>

  		</div>
    </AccountLayout>
  )
}

export default AccountGiftListOrdersTemplate
